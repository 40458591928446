/*------------GLOBAL STYLES--------------*/
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;400;700&display=swap');
.oswald-heading {
  font-family: "Oswald", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;  
  font-style: normal;
}

.oswald-subheading {
  font-family: "Oswald", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400; 
  font-style: normal;
}

.oswald-light {
  font-family: "Oswald", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;  
  font-style: normal;
}
.content {
  
  display: grid;
  grid-template-columns:auto auto; 
    grid-template-rows: auto  auto; 
  place-items: flex;
}
.brand {
  font-size: 2em;
  padding: 10px;
  margin-left: 15px;
  margin-right: 75px;
  
}
head,body, html {
  margin: 0;
  padding: 0;
  font-family: 'oswald', sans-serif;
  background-color: #09396C;
}
main{
  height: auto;
  grid-column: 1 / -1; /* stretch across all columns */
  border-radius: 10px;
  background-color: #ffffff;
  margin-bottom: 2.5rem;
  margin-left: 1vw;
  margin-right: 1vw;
  p{
    padding-left: 30px;
    font-size: x-large;
  }
 
}

h1 {
  padding: 1vw;
  padding-bottom: 0px;
  color: #21314d;
  font-size: 4vw;
  margin-top: 0%;
}
.TopTitle{
  color: #CFDCE9;
  font-size: xx-large;
  margin-top: 0%;
}
header{
  color:#CFDCE9;
  
}
input{
  text-align: center; 
  font-family: 'oswald', sans-serif;
}

nav {
  ul {
    list-style-type: none;
    padding: 0;
  }
  ul li {
    display: inline-block;
    margin-right: 20px;
  }
  a {
    text-decoration: none;
    color: #CFDCE9;
    font-weight: bold;
  }
  a:hover {
    color: #CC4628;
  }
}

.topNav {
  
  padding-top: 30px;
  float: right;
  padding-right: 2vw;
  ul {
    list-style-type: none;
    padding: 0;
  }
  ul li {
    display: inline-block;
    margin-right: 1vw;
  }
  a {
    font-size: 3vw;
    text-decoration: none;
    color: #CFDCE9;
    font-weight: bold;
    padding: 2vw;
  }
  a:hover {
    color: #CC4628;
  }
}

#footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 2.5rem;            /* Footer height */
}
::placeholder {
  text-align: center; 
  font-family: 'oswald', sans-serif;
}
button{
  color: white;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  background: #21314d;
  height: 50px;
  border-radius: 10px;
  cursor: pointer;
  font-family: 'oswald', sans-serif;
  font-size: 20px;
  transition: 0.5s;
  margin-left: 1vw;
}
button:disabled,
button[disabled]{
  color: white;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  background: #9da1a8;
  height: 50px;
  border-radius: 10px;
  cursor: pointer;
  font-family: 'oswald', sans-serif;
  font-size: 20px;
  transition: 0.5s;
}
button:hover:enabled {
  background:#CC4628;
}



input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}
.logo {
  padding-left: 1vw;
  padding-top: 2vw;
  width: 20vw;  /* Adjust this value as needed */
}
/*------------GLOBAL STYLES ENDS--------------*/


/*----------------Login page starts--------------------*/
.login {
  height: 200px;
  width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: -200px;
  button {
    display: block;
  }
}

input[name='user']{
  
  background: #ecf0f3;
  padding: 10px;
  padding-left: 10px;
  height: 25px;
  font-size: 25px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white;
}
input[name='password']{

  background: #ecf0f3;
  padding: 10px;
  padding-left: 10px;
  height: 25px;
  font-size: 25px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white;
}

/*----------------Login page Ends--------------------*/

/*------------featureDetail page starts--------------*/
.detail{
  p{
    padding-left: 30px;

  }
  p:hover{
    color: #CC4628;
  }
  button{
    float: right;
    margin: 20px;
    margin-bottom: 30px;
  }
  
}
.editMode {
  padding-top: 30px;
  padding-left: 30px;;
  font-size: x-large;

  input{
  
    background: #ecf0f3;
    padding: 10px;
    padding-left: 10px;
    width: auto;
    height: 25px;
    font-size: 25px;
    border-radius: 10px;
    box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white;
  }
  textarea{
    background: #ecf0f3;
    padding: 10px;
    padding-left: 10px;
    height: auto;
    width: auto;
    font-size: 15px;
    border-radius: 10px;
    box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white;
  }
  select{
    text-align: center;
    font-family: 'oswald', sans-serif;
    background: #ecf0f3;
    padding: 10px;
    padding-left: 10px;
    height: auto;
    width: auto;
    font-size: 20px;
    border-radius: 10px;
    box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white;
  }

}
/*------------featureDetail page Ends--------------*/

/*------------List page starts--------------*/
.list{
  ul {
    list-style-type: none;
    padding: 0;
  }
  ul li {
    display: block;
    padding-left: 30px;
    padding-top: 5px;
    margin-right: 20px;
    font-size: x-large;
  }
  a {
    font-size: xx-large;
    text-decoration: none;
    color: #21314d;
    font-weight: bold;
  }
  a:hover {
    color: #CC4628;
  }
  a button{
    margin-left: 30px;
  }
 
  span{
    font-size: 20px;
  }
}
/*------------List page Ends--------------*/

/*--------------------------DROPDOWN----------------------------*/

.dropdown {
  position: relative;
  display: inline-block;
  border: none;
  margin-right: -1vw;
}

.dropdown-content {
  border-radius: 25px;
  display: none; /* Initially hidden, shown on some interaction */
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  left: 50%; /* Center horizontally relative to the parent */
  transform: translateX(-50%); /* Shift it back by half its own width */
}

.dropdown-content a {
  border-radius: 25px;
  color: #09396C;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}


.dropbtn {
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown-content a:hover {background-color: #f1f1f1;}
.dropdown:hover .dropdown-content {display: block;}
/*--------------------------DROPDOWN----------------------------*/

/*---------------------------------------------------------------------------------------------*/
/*-----------featureNew page---------------*/
.featureNew {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Split the screen into two equal columns */
  align-items: start; /* Align items to the start of each grid cell */
  gap: 20px; /* Adds space between the columns */
  padding: 20px;
  margin: auto; /* Center the grid horizontally */
}


#map {
  height: 100%; /* Height of the map */
  border-radius: 8px; /* Optional: Rounds the corners of the map */
  float:center;
}

.form-section {
  display: flex;
  flex-direction: column;
  justify-content: start;
}
/*-----------featureNew page Ends---------------*/
.home{
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto auto;
  place-items: flex;
  button{
    color:#09396C;
    font-size: 5vw;
    height:60vh;
    width: 30vw;
    margin-bottom: 30px;
    padding: 30px;
    background-color: #CFDCE9;
    border-color:#879EC3;
    border-radius: 30px;
    padding: 0;
  }
  h1{
    padding-left: 1vw;
    padding-right: 0;
  }

  Link[name='feature']{
    grid-column: 1 ;
  }
  Link[name='location']{
    grid-column: 2 ;
  }
  Link[name='category']{
    grid-column: 3 ;
  }
}
.featureB{
  margin-left: 1vw;
}
.locationB{
  float: center;
  margin-left: 1vw;
}
.categoryB{
  float: right;
  margin-right: 1.5vw;
}
.warning{
  margin-bottom: 0;
  margin-top: 0;
  font-size: 2vw;
}


.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  max-width: 500px;
}

.close {
  float: right;
  font-size: 28px;
  cursor: pointer;
}
.errorImg{
  width: 80%;
  max-width: 400px;
}